export const txn = {
    get: {
        url: 'transaction/getPaymentRequests',
        method: 'GET',
        id: null,
        params: null
    },
    approve: {
        url: 'transaction/approvePaymentRequest/',
        method: 'GET',
        id: null,
        params: null
    },
    reject: {
        url: 'transaction/rejectPayment/',
        method: 'GET',
        id: null,
        params: null
    },
    
}